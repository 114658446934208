<template>
  <div :class="{ bordered: border, shadow: boxShadow }" class="awl-card">
    <div class="awl-card--header" v-if="$scopedSlots.header || $scopedSlots.title || title">
      <slot name="header">
        <h3 class="awl-card--title" v-if="$scopedSlots.title || title">
          <slot name="title">
            {{ title }}
          </slot>
        </h3>
      </slot>
    </div>
    <div class="awl-card--content">
      <slot name="body">
        <div class="card-body">
          <slot></slot>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'awl-card',
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    boxShadow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
  },
};

</script>

<style lang="scss">

.awl-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 15px;
  border: none;

  &.bordered {
    border: 1px solid #ccc;
  }

  .awl-card--header {
    padding: 3rem 3rem 0;

    .awl-card--title {
      color: $primary;
      text-transform: uppercase;
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }

  .awl-card--content {
    width: 100%;
    padding: 3rem;
  }
}

</style>
